import {
  Close,
  Collections,
  ContentCopy,
  ContentCopyTwoTone,
  FileCopy,
  Remove,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useFormik, useFormikContext } from "formik";
import { set } from "ramda";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import StaticResources from "service/dummyData/resources";
import { toTitleCase } from "utils/utils";
import * as yup from "yup";
import PromoItemsService from "../services/promoItems.service";
import { MuiChipsInput } from "lib/mui-chips-input/index";
import { WaysOfBuyingComponent } from "./WaysOfBuying.component";
import {
  CustomFastAsyncSelect,
  CustomFastSelect,
} from "modules/Forms/components/form.legacy.component";
import { useActiveUser } from "modules/User/activeUser";
import { ICatRow } from "../domains/pslMaterial.domain";
import { PSL } from "../domains/psl.domain";
import { BarcodeButton } from "modules/UI/components/BarcodeButton.component";
import AdminService from "modules/Admin/service/admin.service";
import { PromoItemRowSchema } from "modules/PromoItems/services/promoItems.schemas";
import PromoCategorySelect from "modules/Forms/components/promoCategorySelect.component";
import TextFieldWrapper from "modules/Forms/components/textFIeldWrapper.component";

export const CustomCurrencySelect = ({
  value,
  onChange,
  required,
}: {
  value: any;
  onChange: any;
  required?: boolean;
}) => {
  const { data: currencies, isLoading: isLoadingCurrencies } = useQuery({
    queryKey: ["currencies"],
    queryFn: () => PromoItemsService.fetchCurrencies(),
    initialData: [{ CurrencyKey: "CHF", Description: "CHF" }],
  });
  return (
    <FormControl fullWidth size="small">
      <InputLabel htmlFor="currency-select" id="currency-select-label">
        Currency {required && <>*</>}
      </InputLabel>
      <Select
        size="small"
        fullWidth
        labelId="currency-select-label"
        id="currency-select"
        value={value}
        label="Age"
        onChange={(e) => onChange(e.target.value)}
      >
        {currencies.map((currency) => {
          return (
            <MenuItem value={currency.CurrencyKey}>
              {currency.Description}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const CustomLanguageSelect = ({ value, onChange, required, error }) => {
  const { data: languages, isLoading: isLoadingLanguages } = useQuery({
    queryKey: ["languages"],
    queryFn: () => PromoItemsService.fetchLanguages(),
    initialData: [],
  });
  return (
    <FormControl fullWidth size="small" disabled={!!isLoadingLanguages}>
      <InputLabel htmlFor="grouped-select">
        Language {required && "*"}
      </InputLabel>
      <Select
        size="small"
        fullWidth
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        label="Age"
        error={!!error}
        onChange={(e) => onChange(e.target.value)}
      >
        {languages.map((language) => {
          return <MenuItem value={language.id}>{language.name}</MenuItem>;
        })}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

const CustomMaterialSelect = ({ value, onChange, values, promoItem }) => {
  const { data: amdrMaterials, isLoading: isLoadingAmdrMaterials } = useQuery({
    queryKey: ["amdrMaterials", promoItem.spendCat3Code],
    queryFn: () =>
      PromoItemsService.getPslAmdrMaterials({
        materialgroup: promoItem.spendCat3Code,
      }),
    initialData: [],
  });
  return (
    <FormControl fullWidth size="small" disabled={!!isLoadingAmdrMaterials}>
      <InputLabel>Material number</InputLabel>
      <Select
        size="small"
        fullWidth
        value={value}
        label="Age"
        onChange={(e) => onChange(e.target.value)}
      >
        {amdrMaterials.map((material) => {
          return (
            <MenuItem value={material.id}>
              [{material.id}] {material.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

interface MaterialSelectorProps {
  companyCodes: string;
  spendCat3Code: string;
  initialValue: { value: string; label: string } | null;
  applyChange: (newValue: { value: string; label: string }) => void;
  disabled: boolean;
}
export const MaterialSelector = (props: MaterialSelectorProps) => {
  return (
    <div style={{ position: "relative", zIndex: "20" }}>
      <CustomFastAsyncSelect
        disabled={props.disabled}
        onLoad={async (searchTerm) => {
          const results = await PromoItemsService.getPslAmdrMaterials({
            materialgroup: props.spendCat3Code,
            searchTerm,
            locationCompanyCodes: props.companyCodes,
          });

          return results?.map((option) => {
            return {
              value: option.id,
              label: `${option.name}`,
              ...option,
            };
          });
        }}
        onLoadLimit={3}
        customDisplay={(option) => `${option.value} - ${option.label}`}
        initialValue={props.initialValue}
        onChange={(value) => props.applyChange(value)}
      />
    </div>
  );
};

export const PromoItemRow = (props: {
  promoItem: any;
  defaultSpendCategories: any;
  promoItemIndex: number;
  onRemove: (promoItem: any) => void;
  onDuplicate: (promoItem: any) => void;
  onManageMedia: () => void;
}) => {
  const activeUser = useActiveUser();
  const { values, setValues, setFieldValue, getFieldMeta } =
    useFormikContext<any>();
  const pslClass = useMemo(() => {
    return new PSL(values.vendor);
  }, [values]);
  const { data: amdrMaterials, isLoading: isLoadingAmdrMaterials } = useQuery({
    queryKey: ["amdrMaterials", props.promoItem.spendCat3Code, values.vendor],
    queryFn: () => {
      return PromoItemsService.getPslAmdrMaterials({
        materialgroup: props.promoItem.spendCat3Code,
        locationCompanyCodes: "" /*values.vendor?.companyCodes
          ?.map((companyCode) => companyCode.code)
          .join(","),*/,
        // locationMarkets: values.vendor?.markets
        //   ?.map((market) => market.id)
        //   .join(","),
        // locationZones: values.vendor?.zones?.map((zone) => zone.id).join(","),
      });
    },
    initialData: [],
  });
  const { data: plants, isLoading: plantsLoading } = useQuery({
    queryKey: [
      "plants",
      values.vendor,
      values.materials[props.promoItemIndex].materialNumber,
    ],
    queryFn: () =>
      PromoItemsService.fetchPslAmdrPlantsV2({
        text: null,
        locationZones: values.vendor?.zones?.map((zone) => zone.id).join(","),
        locationMarkets: values.vendor?.markets
          ?.map((market) => market.id)
          .join(","),
        locationCompanyCodes: values.vendor?.companyCodes
          ?.map((companyCode) => companyCode.code)
          .join(","),
        materialNumber: values.materials[props.promoItemIndex].materialNumber,
      }),
    enabled: !!values.materials[props.promoItemIndex].materialNumber,
    initialData: [],
  });

  return isLoadingAmdrMaterials || plantsLoading ? (
    <>Loading...</>
  ) : (
    <Card
      sx={{
        padding: 2,
        borderRadius: 2,
        backgroundColor: "#F9F9F9",
        mb: 2,
        overflow: "visible",
      }}
    >
      <CardHeader
        title={
          <Breadcrumbs separator={">"}>
            <Tooltip title={props.promoItem.spendCat1DisplayValue}>
              {props.promoItem.spendCat1Code}
            </Tooltip>
            <Tooltip title={props.promoItem.spendCat2DisplayValue}>
              {props.promoItem.spendCat2Code}
            </Tooltip>
            <Tooltip title={props.promoItem.spendCat3DisplayValue}>
              {props.promoItem.spendCat3Code}
            </Tooltip>
          </Breadcrumbs>
        }
        titleTypographyProps={{ color: "primary", variant: "body1" }}
        action={
          <ButtonGroup>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                props.onManageMedia();
              }}
              startIcon={<Collections />}
            >
              Manage Media ({props.promoItem.mediaFiles?.length || 0})
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                props.onDuplicate(props.promoItem);
              }}
              startIcon={<FileCopy />}
            >
              Duplicate
            </Button>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<Close />}
              onClick={() => props.onRemove(props.promoItem)}
            >
              Delete
            </Button>
          </ButtonGroup>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            sx={{
              width: 420,
              flexBasis: 420,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <PromoCategorySelect
                  error={
                    getFieldMeta(
                      `materials[${props.promoItemIndex}]["promoCategoryId"]`
                    ).error
                  }
                  value={
                    values.materials[props.promoItemIndex][
                      "promoCategoryId"
                    ] as string
                  }
                  onChange={(e) => {
                    setFieldValue(
                      `materials[${props.promoItemIndex}].promoCategoryId`,
                      e.target.value
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 420,
              flexBasis: 420,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  size="small"
                  disabled={!!isLoadingAmdrMaterials}
                  error={!!getFieldMeta("vendor.materialNumber").error}
                >
                  {/* <InputLabel
                    htmlFor="select-material-number"
                    sx={{ position: "static", mb: -2 }}
                  >
                    {`Material number
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "materialNumber"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  </InputLabel> */}
                  <MaterialSelector
                    disabled={
                      pslClass.isChildPslNotLocalized() ||
                      (pslClass.isAboveMarket() && activeUser.hasAtMarketRole())
                    }
                    spendCat3Code={
                      values.materials[props.promoItemIndex].spendCat3Code
                    }
                    companyCodes={""}
                    // l3={l3}
                    initialValue={{
                      label:
                        values.materials[props.promoItemIndex].materialName,
                      value:
                        values.materials[props.promoItemIndex].materialNumber,
                    }}
                    applyChange={(material) => {
                      setFieldValue(
                        `materials[${props.promoItemIndex}].materialNumber`,
                        material?.value || ""
                      );
                      setFieldValue(
                        `materials[${props.promoItemIndex}].materialName`,
                        material?.label || ""
                      );
                    }}
                  />
                  {!!getFieldMeta(
                    `materials[${props.promoItemIndex}]["materialNumber"]`
                  ).error && (
                    <FormHelperText error>
                      {
                        getFieldMeta(
                          `materials[${props.promoItemIndex}]["materialNumber"]`
                        ).error
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {!activeUser.hasAboveMarketRole() && (
            <Grid
              item
              sx={{
                width: 420,
                flexBasis: 420,
                flexShrink: 0,
                flexGrow: 1,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <CustomFastSelect
                    placeholder="Select plants"
                    loading={plantsLoading}
                    isMulti={true}
                    customDisplay={(option) =>
                      `${option.value} - ${option.label}`
                    }
                    initialValue={values.materials[
                      props.promoItemIndex
                    ].plants?.map((p) => ({ value: p.code, label: p.name }))}
                    options={plants.map((v) => ({
                      value: v.code,
                      label: v.name,
                    }))}
                    onChange={(value) =>
                      setFieldValue(
                        `materials[${props.promoItemIndex}].plants`,
                        value.map((v) => ({ code: v.value, name: v.label }))
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            sx={{
              width: 250,
              flexBasis: 250,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextFieldWrapper
                  name={`materials[${props.promoItemIndex}].shortDescription`}
                  label={`Short Description 
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "shortDescription"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 250,
              flexBasis: 250,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextFieldWrapper
                  name={`materials[${props.promoItemIndex}].unitPrice`}
                  label={`Unit Price 
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "unitPrice"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 140,
              flexBasis: 140,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <CustomCurrencySelect
                  value={values.materials[props.promoItemIndex].currencyCode}
                  onChange={(newValue) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].currencyCode`,
                      newValue
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 100,
              flexBasis: 100,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextFieldWrapper
                  name={`materials[${props.promoItemIndex}].pricePer`}
                  label={`Price Per 
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "pricePer"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 150,
              flexBasis: 150,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    htmlFor="unit-of-measure-select"
                    id="unit-of-measure-label"
                  >
                    {`Unit of Measure 
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "unitOfMeasure"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  </InputLabel>
                  <Select
                    size="small"
                    fullWidth
                    labelId="unit-of-measure-label"
                    id="unit-of-measure-select"
                    error={
                      !!getFieldMeta(
                        `materials[${props.promoItemIndex}]["unitOfMeasure"]`
                      ).error
                    }
                    value={values.materials[props.promoItemIndex].unitOfMeasure}
                    onChange={(e) =>
                      setFieldValue(
                        `materials[${props.promoItemIndex}].unitOfMeasure`,
                        e.target.value
                      )
                    }
                  >
                    {(
                      amdrMaterials
                        ?.find(
                          (m) =>
                            m.id ==
                            values.materials[props.promoItemIndex]
                              .materialNumber
                        )
                        ?.uoM?.split(",") || ["EA"]
                    ).map((uom) => {
                      return <MenuItem value={uom}>{uom}</MenuItem>;
                    })}
                  </Select>
                  {!!getFieldMeta(
                    `materials[${props.promoItemIndex}]["unitOfMeasure"]`
                  ).error && (
                    <FormHelperText error>
                      {
                        getFieldMeta(
                          `materials[${props.promoItemIndex}]["unitOfMeasure"]`
                        ).error
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid
            item
            sx={{
              width: 320,
              flexBasis: 320,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <WaysOfBuyingComponent
                  initialValue={
                    values.materials[props.promoItemIndex].waysOfBuying
                  }
                  onChange={(value: any[]) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].waysOfBuying`,
                      value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid
            item
            sx={{
              width: 250,
              flexBasis: 250,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Scope of Application
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "itemDescription"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  error={
                    !!getFieldMeta(
                      `materials[${props.promoItemIndex}]["itemDescription"]`
                    ).error
                  }
                  helperText={
                    getFieldMeta(
                      `materials[${props.promoItemIndex}]["itemDescription"]`
                    ).error ?? null
                  }
                  value={values.materials[props.promoItemIndex].itemDescription}
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].itemDescription`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 200,
              flexBasis: 200,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Supplier Part Id
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "supplierPartId"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  slotProps={{
                    input: {
                      endAdornment: !values.materials[props.promoItemIndex]
                        .supplierPartId ? null : (
                        <BarcodeButton
                          value={
                            values.materials[props.promoItemIndex]
                              .supplierPartId || ""
                          }
                        />
                      ),
                    },
                  }}
                  value={values.materials[props.promoItemIndex].supplierPartId}
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].supplierPartId`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 200,
              flexBasis: 200,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Manufacturer Name
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "manufacturerName"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  value={
                    values.materials[props.promoItemIndex].manufacturerName
                  }
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].manufacturerName`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 220,
              flexBasis: 220,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Manufacturer Part Number
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "manufacturerPartNumber"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  value={
                    values.materials[props.promoItemIndex]
                      .manufacturerPartNumber
                  }
                  slotProps={{
                    input: {
                      endAdornment: !values.materials[props.promoItemIndex]
                        .manufacturerPartNumber ? null : (
                        <BarcodeButton
                          value={
                            values.materials[props.promoItemIndex]
                              .manufacturerPartNumber || ""
                          }
                        />
                      ),
                    },
                  }}
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].manufacturerPartNumber`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 200,
              flexBasis: 200,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Supplier Lead Time
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "supplierLeadTime"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  value={
                    values.materials[props.promoItemIndex].supplierLeadTime
                  }
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].supplierLeadTime`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 420,
              flexBasis: 330,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <MuiChipsInput
                  error={
                    !!getFieldMeta(
                      `materials[${props.promoItemIndex}]["keyWords"]`
                    ).error
                  }
                  helperText={
                    getFieldMeta(
                      `materials[${props.promoItemIndex}]["keyWords"]`
                    ).error
                  }
                  value={values.materials[props.promoItemIndex].keyWords}
                  onChange={(newValue) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].keyWords`,
                      newValue
                    )
                  }
                  size="small"
                  fullWidth
                  label={`Key Words 
                    ${
                      !(PromoItemRowSchema.describe().fields["keyWords"] as any)
                        ?.optional
                        ? "*"
                        : ""
                    }`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 140,
              flexBasis: 140,
              flexShrink: 0,
              flexGrow: 0,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <CustomLanguageSelect
                  required={
                    !(PromoItemRowSchema.describe().fields["languageId"] as any)
                      ?.optional
                  }
                  error={
                    getFieldMeta(
                      `materials[${props.promoItemIndex}]["languageId"]`
                    ).error
                  }
                  value={values.materials[props.promoItemIndex].languageId}
                  onChange={(newValue) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].languageId`,
                      newValue
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PromoItemRow;
