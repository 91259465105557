import { TreeSelect } from "antd";
import * as React from "react";
import { useEffect } from "react";
import {
  IUserFullScope,
  IUserScope,
  IUserScopeCompany,
  UserService,
} from "modules/User/service/user.service";
import { includes } from "ramda";
import { useQuery } from "@tanstack/react-query";

interface IdTitlePair {
  id: number;
  title: string;
}

type Props = {
  company?: string;
  isViewOnly?: boolean;
  initialMarkets: IdTitlePair[];
  initialCompanies: IdTitlePair[];
  initialZones: IdTitlePair[];
  hasError: boolean;
  onChange: Function;
  disabled?: boolean;
  clear?: boolean;
  disableZones?: boolean;
};

export function UserFullScopeComponent({
  company = "",
  isViewOnly = false,
  initialMarkets = [],
  initialCompanies = [],
  initialZones = [],
  onChange,
  hasError,
  disabled = false,
  clear = false,
  disableZones = false,
}: Props) {
  const [userScope, setUserScope] = React.useState([]);
  const [selectedUserScope, setSelectedUserScope] = React.useState(
    []
      .concat(
        initialCompanies.map((el) => ({
          value: "company-" + el?.id,
          label: el?.title,
        }))
      )
      .concat(
        initialMarkets.map((el) => ({
          value: "market-" + el?.id,
          label: el?.title,
        }))
      )
      .concat(
        initialZones.map((el) => ({
          value: "zone-" + el?.id,
          label: el?.title,
        }))
      )
  );

  useEffect(() => {
    setSelectedUserScope((prev) => {
      return prev
        .concat(
          initialCompanies.map((el) => ({
            value: "company-" + el?.id,
            label: el?.title,
          }))
        )
        .concat(
          initialMarkets.map((el) => ({
            value: "market-" + el?.id,
            label: el?.title,
          }))
        )
        .concat(
          initialZones.map((el) => ({
            value: "zone-" + el?.id,
            label: el?.title,
          }))
        );
    });
  }, [initialCompanies, initialMarkets, initialZones]);
  const { data: response } = useQuery({
    queryKey: ["userFullScope"],
    queryFn: () => UserService.fetchUserFullScope(),
  });
  useEffect(() => {
    let isSubscribed = true;
    if (response && isSubscribed) {
      const zonesAndMarketsAndCompanies = (response as IUserFullScope[]).filter(
        (item) => item.fullZoneAccess || item.markets.length > 0
      );

      const tree = zonesAndMarketsAndCompanies.map((el: IUserFullScope) => ({
        title: el.zone?.name,
        disableCheckbox: !el.fullZoneAccess || disableZones,
        value: "zone-" + el.zone?.id,
        key: "zone-" + el.zone?.id,
        children: el.markets.map((el: IUserScope) => ({
          title: el.market?.name,
          disableCheckbox: !el.fullMarketAccess,
          value: "market-" + el.market?.id,
          key: "market-" + el.market?.id,
          children: el.companyCodes.map((c: IUserScopeCompany) => ({
            title: `${c.code} - ${c.name}`,
            value: "company-" + c.id,
            key: "company-" + c.id,
          })),
        })),
      }));
      const findCompany = (array) => {
        for (const item of array) {
          const result = item.title === company;
          if (result) return item;
        }
      };
      const findParent = (array) => {
        for (const item of array) {
          const result = findCompany(item.children);
          if (result) {
            item.children = [result];
            return item;
          }
        }
      };
      if (isViewOnly) {
        const treeForViewOnly = findParent(tree);
        setUserScope([treeForViewOnly]);
      } else {
        setUserScope(tree);
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [response]);

  useEffect(() => {
    if (clear) {
      setSelectedUserScope([]);
    }
  }, [clear]);

  const onChangeUserScope = (
    originalValues: { value: string; label: string; code: string }[]
  ) => {
    setSelectedUserScope(originalValues);
    onChange({
      zones: originalValues
        .filter((el) => el && el.value?.indexOf("zone-") !== -1)
        .map((el) => ({ id: el.value.split("zone-")[1], title: el.label })),
      markets: originalValues
        .filter((el) => el && el.value?.indexOf("market-") !== -1)
        .map((el) => ({ id: el.value.split("market-")[1], title: el.label })),
      companyCodes: originalValues
        .filter((el) => el && el.value?.indexOf("company-") !== -1)
        .map((el) => ({
          id: el.value.split("company-")[1],
          title: el.label,
          code: el.label.split(" - ")[0],
        })),
    });
  };

  const userScopeProps = {
    treeData: userScope,
    value: selectedUserScope,
    onChange: onChangeUserScope,
    disabled: disabled,
    treeCheckable: true,
    treeCheckStrictly: false,
    dropdownMatchSelectWidth: false,
    treeNodeFilterProp: "title",
    showCheckedStrategy: TreeSelect.SHOW_PARENT,
    labelInValue: true,
    placeholder: "Type 2 characters to start searching",
    filterTreeNode: (inputValue: string, treeNode: any) => {
      if (inputValue.length < 2) return false;
      return includes(inputValue.toUpperCase(), treeNode.title.toUpperCase());
    },
  };

  const getStyle = () =>
    hasError
      ? {
          border: "1px solid red",
          borderRadius: "4px",
          width: "100%",
          minHeight: "38px",
        }
      : { width: "100%", minHeight: "38px" };

  return (
    <TreeSelect
      {...userScopeProps}
      dropdownStyle={{ zIndex: 99999 }}
      style={getStyle()}
    />
  );
}
