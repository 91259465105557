import React, { useEffect, useState } from "react";
import { CampaignShortDTO } from "../service/campaigns.types";
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
} from "@mui/material";
import {
  NotificationAddOutlined,
  Share,
  Visibility,
} from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { createBlobService } from "utils/factories";

export type CampaignCardProps = {
  id: string | number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  imageUrl: string;
};

export const CampaignCard = (props: CampaignCardProps) => {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    createBlobService(props.imageUrl, "GET")().then((blob) => {
      setImageUrl(URL.createObjectURL(blob));
    });
  }, [props.imageUrl]);

  return (
    <Card
      sx={{
        width: 320,
        flex: "0 0 320px",
        mt: 3,
        mb: 6,
        transition: ".2s ease-out",
        position: "relative",
        userSelect: "none",
        "&:hover": {
          transform: "scale(1.1);",
          boxShadow: (theme) => theme.shadows[8],
          zIndex: 20,
        },
      }}
    >
      <CardHeader
        title={props.name}
        subheader={`Ending ${moment(props.endDate).fromNow()}`}
      />
      <CardMedia
        component="img"
        height={(320 * 9) / 16}
        image={imageUrl}
        sx={{ pointerEvents: "none" }}
      />
      <CardActions>
        <ButtonGroup sx={{ ml: "auto" }}>
          {!!navigator.share && (
            <Button
              onClick={async () => {
                try {
                  await navigator.share({
                    title: props.name,
                    text: props.description,
                    url: "https://example.com",
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Share />
            </Button>
          )}
          <Button>
            <NotificationAddOutlined />
          </Button>
          <Button onClick={() => navigate(`/campaigns/${props.id}`)}>
            Details
          </Button>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default CampaignCard;
