import * as yup from "yup";

export const PromoItemRowSchema = yup.object().shape({
  deleted: yup.boolean(),
  currencyCode: yup.string().nullable(),
  itemDescription: yup.string().required("this field is required"),
  keyWords: yup
    .array()
    .of(yup.string().required())
    .when("deleted", {
      is: false || undefined,
      then: (schema) =>
        schema
          .min(1, "At least one key word is required")
          .required("This field is required."),
    }),
  languageId: yup.string().required("this field is required"),
  languageTag: yup.string().nullable(),
  manufacturerName: yup.string().nullable(),
  manufacturerPartNumber: yup.string().nullable(),
  marketContactName: yup.string().nullable(),
  matGrp: yup.string().nullable(),
  materialId: yup.string().nullable(),
  materialName: yup.string().nullable(),
  materialNumber: yup.string().required("this field is required"),
  parentMaterialId: yup.string().nullable(),
  pricePer: yup.string().nullable(),
  promoCategoryId: yup.string().required("this field is required"),
  shortDescription: yup
    .string()
    .notRequired()
    .when("deleted", {
      is: false,
      then: (schema) => schema.required("this field is required"),
    }),
  spendCat1Code: yup.string().nullable(),
  spendCat1DisplayValue: yup.string().nullable(),
  spendCat1Id: yup.string().nullable(),
  spendCat2Code: yup.string().nullable(),
  spendCat2DisplayValue: yup.string().nullable(),
  spendCat2Id: yup.string().nullable(),
  spendCat3Code: yup.string().nullable(),
  spendCat3DisplayValue: yup.string().nullable(),
  spendCat3Id: yup.string().nullable(),
  supplierLeadTime: yup.string().nullable(),
  supplierPartId: yup.string().nullable(),
  unitOfMeasure: yup.string().required("this field is required"),
  unitPrice: yup.string().nullable(),
  waysOfBuying: yup.array().of(yup.number()),
  mediaFiles: yup.array().of(
    yup.object().shape({
      fileId: yup.string(),
      mimeType: yup.string(),
      originalFileName: yup.string(),
      persistedFileNameInAzureStorage: yup.string(),
      url: yup.string(),
    })
  ),
});
