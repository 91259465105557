import { Add, Campaign, Create, Share, Visibility } from "@mui/icons-material";
import { Box, Container, Fab, Grid, Typography } from "@mui/material";
import { Slide } from "modules/Layout/views/landing.view";
import Drawer from "modules/UI/components/Drawer.component";
import PUIRatioBox from "modules/UI/components/RatioBox.component";
import React, { useEffect, useRef } from "react";
import CreateCampaignContainer from "./create.container";
import { useQuery } from "@tanstack/react-query";
import CampaignsService from "../service/campaigns.service";
import { useNavigate } from "react-router-dom";
import CampaignCard from "modules/Campaigns/components/CampaignCard.component";

export const CampaignsListContainer = () => {
  const navigate = useNavigate();
  const activeCampaignsRef = useRef(null);
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const { data: campaignsResults } = useQuery({
    queryKey: ["campaigns/get-filtered"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 10,
      });
      return response;
    },
    initialData: { data: [], take: 10, skip: 0, total: 0 },
  });

  useEffect(() => {
    const slider = activeCampaignsRef.current;
    let mouseDown = false;
    let startX, scrollLeft;

    const startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const stopDragging = (e) => {
      mouseDown = false;
    };

    const move = (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    };

    // Add the event listeners
    slider.addEventListener("mousemove", move, false);
    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
    return () => {
      // Clean up the event listeners
      slider.removeEventListener("mousemove", move, false);
      slider.removeEventListener("mousedown", startDragging, false);
      slider.removeEventListener("mouseup", stopDragging, false);
      slider.removeEventListener("mouseleave", stopDragging, false);
    };
  }, []);

  return (
    <>
      <Drawer
        title="Create Campaign"
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      >
        {createDialogOpen && (
          <CreateCampaignContainer onClose={() => setCreateDialogOpen(false)} />
        )}
      </Drawer>
      <Box sx={{ m: -3, mb: 3 }}>
        <PUIRatioBox xRatio={16} yRatio={9}>
          <Slide
            isActive={activeSlide === 0}
            backgroundImage="https://i.ytimg.com/vi/5n4J9duypeQ/maxresdefault.jpg"
            onNext={() => {
              setActiveSlide(1);
            }}
          />
          <Slide
            isActive={activeSlide === 1}
            backgroundImage="https://mir-s3-cdn-cf.behance.net/project_modules/fs/de70ae11079143.560f10407d139.jpg"
            onPrevious={() => {
              setActiveSlide(0);
            }}
          />
        </PUIRatioBox>
      </Box>
      <Fab
        color="primary"
        variant="extended"
        sx={{ position: "fixed", bottom: 50, right: 50 }}
        onClick={() => setCreateDialogOpen(true)}
      >
        <Campaign sx={{ mr: 1 }} />
        New Campaign
      </Fab>

      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} mt={6}>
            <Typography variant="h2">Active Campaigns</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            overflow={"auto"}
            gap={3}
            sx={{
              "&::-webkit-scrollbar": {
                width: 0,
              },
            }}
            ref={activeCampaignsRef}
          >
            {campaignsResults?.data?.map((campaign) => (
              <CampaignCard
                key={campaign.id}
                {...campaign}
                imageUrl={campaign.mediaFiles[0]?.url}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CampaignsListContainer;
