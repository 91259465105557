import { ListItemText, MenuItem, Select } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import PromoCatalogService from "service/PromoCatalog.service";

export const SelectSupplierContact = (props: {
  supplierId?: string;
  value?: string;
  onChange?: (e: any) => void;
}) => {
  const { data: supplierContacts, isLoading: isLoadingSupplierContacts } =
    useQuery({
      queryKey: ["supplier-contacts/get-all", props.supplierId],
      queryFn: async () => {
        const response = await PromoCatalogService.getSupplierContacts({
          supplierId: props.supplierId,
        });
        return response;
      },
      initialData: [],
    });

  return (
    <div>
      <Select
        fullWidth
        size="small"
        disabled={isLoadingSupplierContacts || !props.supplierId}
        placeholder={
          props.supplierId ? "Select supplier contact" : "Select supplier first"
        }
        onChange={props.onChange}
        value={props.value}
      >
        {supplierContacts.map((contact) => (
          <MenuItem key={contact.id} value={contact.email}>
            <ListItemText
              primary={`${contact.firstname} ${contact.lastname}`}
              secondary={contact.email}
            />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SelectSupplierContact;
