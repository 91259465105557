import {
  createQueryService,
  createFormDataService,
  createMutationService,
} from "utils/factories";
import { CampaignShortDTO, CreateCampaignDTO } from "./campaigns.types";
import { PaginatedQueryResult } from "service/generic.types";

const moduleBasePath = "/api/order-windows";

export default class CampaignsService {
  static readonly getCampaigns = createMutationService<
    PaginatedQueryResult<CampaignShortDTO>,
    any
  >(`${moduleBasePath}/get-filtered`);

  static readonly createCampaign = createMutationService<
    CampaignShortDTO,
    CreateCampaignDTO
  >(`${moduleBasePath}/create`);

  static readonly deleteCampaignMedia = createMutationService<
    any,
    { fileId: string }
  >(({ fileId }) => `${moduleBasePath}/files/${fileId}`, "DELETE");

  static readonly uploadCampaignMediaFromLink = createMutationService<
    any,
    { link: string; orderWindowId?: string }
  >(
    ({ link, orderWindowId }) =>
      `${moduleBasePath}/files/upload-file-from-link?link=${link}${
        orderWindowId ? `&orderWindowId=${orderWindowId}` : ""
      }`,
    "POST"
  );
  static readonly uploadCampaignMedia = createFormDataService<any, any>(
    ({ orderWindowId }) =>
      `${moduleBasePath}/files/upload-file?${
        orderWindowId ? `orderWindowId=${orderWindowId}` : ""
      }`,
    "POST"
  );
}
