import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import AdminService from "../service/admin.service";
import { MaterialReactTable } from "material-react-table";
import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Drawer from "modules/UI/components/Drawer.component";
import {
  Check,
  Close,
  Delete,
  DeleteForever,
  Edit,
  Palette,
} from "@mui/icons-material";
import { useConfirmation } from "modules/UI/hooks/useConfirmation.hook";
import PUIRatioBox from "modules/UI/components/RatioBox.component";
import { useFeatures } from "modules/FeatureManagement/service/featureManagement.hooks";
import NotFoundView from "modules/Layout/views/notFound.view";
import UnauthorizedView from "modules/Layout/views/unauthorized.view";
import MasterDataService from "modules/MasterData/service/masterData.service";

export const PromoCategoriesContainer = () => {
  const features = useFeatures();
  const deleteConfirmation = useConfirmation({
    title: "Delete Promo Category",
  });
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: ["promo-categories/get-all"],
    queryFn: async () => {
      const response = await AdminService.getAllPromoCategories();
      return response;
    },
    initialData: [],
  });

  // useEffect(() => {
  //   MasterDataService.getAllCurrencies().then((response) => {
  //     console.log(response);
  //   });
  // }, []);

  return !features["admin-panel"] ? (
    <UnauthorizedView />
  ) : (
    <div>
      {deleteConfirmation.JSX}
      <Drawer
        open={!!selectedCategory}
        onClose={() => setSelectedCategory(null)}
        title={
          selectedCategory?.id ? (
            <>
              Edit <em>{selectedCategory?.name}</em>
            </>
          ) : (
            "Create new promo category"
          )
        }
        footerContent={
          <Grid container spacing={2} justifyContent={"flex-end"}>
            <Grid item>
              <Button variant="outlined" color="primary">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  AdminService[
                    selectedCategory.id
                      ? "updatePromoCategory"
                      : "createPromoCategory"
                  ](selectedCategory)
                    .then(() => {
                      refetch();
                      setSelectedCategory(null);
                    })
                    .catch((e) => {
                      console.error(e);
                    });
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Box sx={{ m: -2, mb: 2 }}>
          <PUIRatioBox xRatio={16} yRatio={9}>
            <Avatar
              variant="square"
              sx={{
                width: "100%",
                height: "100%",
                // background: "linear-gradient(112.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(157.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(135deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(90deg, rgb(195, 195, 195),rgb(228, 228, 228)); background-blend-mode:overlay,overlay,overlay,multiply",
                backgroundColor: selectedCategory?.color || "#ccc",
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,1), rgba(255,255,255,.1))",
                backgroundBlendMode: "overlay",
                "& div": {
                  opacity: 0.2,
                  width: "50%",
                  height: "50%",
                  "& svg": {
                    width: "100%",
                    height: "100%",
                  },
                },
              }}
            >
              {selectedCategory ? (
                <div
                  dangerouslySetInnerHTML={{ __html: selectedCategory?.icon }}
                />
              ) : (
                <></>
              )}
            </Avatar>
          </PUIRatioBox>
        </Box>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Name"
            value={selectedCategory?.name}
            onChange={(e) => {
              setSelectedCategory({
                ...selectedCategory,
                name: e.target.value,
              });
            }}
          />
          <Select
            fullWidth
            label="Type"
            value={selectedCategory?.promoCategoryType}
            onChange={(e) => {
              setSelectedCategory({
                ...selectedCategory,
                promoCategoryType: e.target.value,
              });
            }}
          >
            <MenuItem value={0}>Type A</MenuItem>
            <MenuItem value={1}>Type B</MenuItem>
            <MenuItem value={2}>Type C</MenuItem>
          </Select>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={selectedCategory?.qualityControl}
                  onChange={(e) => {
                    setSelectedCategory({
                      ...selectedCategory,
                      qualityControl: e.target.checked,
                    });
                  }}
                />
              }
              label="Requires Quality Control"
            />
          </FormGroup>
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              primary="Color:"
              secondary={
                <input
                  style={{ width: "100%" }}
                  type="color"
                  value={selectedCategory?.color}
                  onChange={(e) =>
                    setSelectedCategory({
                      ...selectedCategory,
                      color: e.target.value,
                    })
                  }
                />
              }
            />
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            {!!selectedCategory && (
              <ListItemIcon
                dangerouslySetInnerHTML={{ __html: selectedCategory?.icon }}
              />
            )}
            <ListItemText
              primary="Icon:"
              secondary={
                <input
                  type="file"
                  accept={"image/svg+xml"}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = () => {
                      setSelectedCategory({
                        ...selectedCategory,
                        icon: reader.result,
                      });
                    };
                    reader.readAsText(file);
                  }}
                />
              }
            />
          </ListItem>
        </Stack>
      </Drawer>
      <MaterialReactTable
        // enableGrouping
        muiTablePaperProps={({ table }) => ({
          style: { background: "white !important" },
          position: "relative",
          zIndex: table.getState().isFullScreen ? 1300 : undefined,
        })}
        state={{
          isLoading: isLoading,
          showProgressBars: isFetching,
        }}
        initialState={{
          //   grouping: ["promoCategoryType"],
          pagination: {
            pageIndex: 0,
            pageSize: 50,
          },
          columnPinning: {
            left: ["mrt-row-expand", "mrt-row-select"],
            right: ["mrt-row-actions"],
          },
        }}
        data={data}
        enableRowActions
        renderTopToolbarCustomActions={() => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSelectedCategory({})}
            >
              Add Promo Category
            </Button>
          );
        }}
        renderRowActionMenuItems={({ row, closeMenu }) => {
          return [
            <MenuItem
              key="edit"
              disabled={row.original.isDeleted}
              onClick={() => {
                setSelectedCategory(row.original);
                closeMenu();
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>,
            <MenuItem
              key="delete"
              disabled={row.original.isDeleted}
              onClick={() => {
                closeMenu();
                deleteConfirmation
                  .requestConfirmation()
                  .then((reason) => {
                    AdminService.deletePromoCategory({
                      id: row.original.id,
                    }).then(() => {
                      refetch();
                    });
                  })
                  .catch(() => {});
              }}
            >
              <ListItemIcon>
                <DeleteForever fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>,
          ];
        }}
        columns={[
          {
            accessorKey: "id",
            header: "ID",
          },
          {
            accessorKey: "name",
            header: "Name",
            Cell: ({ row }) => {
              return `${row.original.isDeleted ? "[DELETED] " : ""}${
                row.original.name
              }`;
            },
          },
          {
            accessorKey: "qualityControl",
            header: "Quality Control",
            Cell: ({ row }) => {
              return row.original.qualityControl ? (
                <Chip icon={<Check />} color="success" label="Yes" />
              ) : (
                <Chip icon={<Close />} color="error" label="No" />
              );
            },
          },
          {
            accessorKey: "promoCategoryType",
            header: "Type",
            size: 100,
            Cell: ({ row }) => {
              return { 0: "Type A", 1: "Type B", 2: "Type C" }[
                row.original.promoCategoryType
              ];
            },
          },
          {
            accessorKey: "color",
            header: "Color",
            Cell: ({ row }) => {
              return (
                <Chip
                  icon={<Palette />}
                  label={row.original.color || "No color"}
                  style={{ backgroundColor: row.original.color }}
                />
              );
            },
          },
          {
            accessorKey: "icon",
            header: "Icon",
            Cell: ({ row }) => {
              return (
                <div
                  style={{ width: 100 }}
                  dangerouslySetInnerHTML={{ __html: row.original.icon }}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default PromoCategoriesContainer;
