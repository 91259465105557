import { FormControl, FormHelperText, Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import PromoCategorySelect from "modules/Forms/components/promoCategorySelect.component";
import {
  CustomCurrencySelect,
  CustomLanguageSelect,
  MaterialSelector,
} from "modules/PromoItems/components/PromoItemRow.component";
import React, { useEffect, useState } from "react";
import { CampaignMaterialType } from "../service/campaigns.types";
import { CampaignMaterialSchema } from "../service/campaigns.schemas";
import { CustomFastSelect } from "modules/Forms/components/form.legacy.component";
import TextFieldWrapper from "modules/Forms/components/textFIeldWrapper.component";
import { BarcodeButton } from "modules/UI/components/BarcodeButton.component";
import { MuiChipsInput } from "lib/mui-chips-input";

export const CampaignMaterialForm = () => {
  const initialValues: CampaignMaterialType = {};
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CampaignMaterialSchema}
      onSubmit={() => {}}
    >
      {({ values, setFieldValue, getFieldMeta }) => (
        <Stack spacing={2}>
          <PromoCategorySelect
            error={getFieldMeta(`promoCategoryId`).error}
            value={values.promoCategoryId as string}
            onChange={(e) => {
              setFieldValue(`promoCategoryId`, e.target.value);
            }}
          />
          <FormControl
            fullWidth
            size="small"
            // disabled={!!isLoadingAmdrMaterials}
            error={!!getFieldMeta("vendor.materialNumber").error}
          >
            {/* <InputLabel
                    htmlFor="select-material-number"
                    sx={{ position: "static", mb: -2 }}
                  >
                    {`Material number
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "materialNumber"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  </InputLabel> */}
            <MaterialSelector
              disabled={false} //ToDo: check promoitem row for this condition
              spendCat3Code={values.spendCat3Code}
              companyCodes={""}
              // l3={l3}
              initialValue={{
                label: values.materialName,
                value: values.materialNumber,
              }}
              applyChange={(material) => {
                setFieldValue(`materialNumber`, material?.value || "");
                setFieldValue(`materialName`, material?.label || "");
              }}
            />
            {!!getFieldMeta(`materialNumber`).error && (
              <FormHelperText error>
                {getFieldMeta(`materialNumber`).error}
              </FormHelperText>
            )}
          </FormControl>

          {/* <CustomFastSelect
                    placeholder="Select plants"
                    loading={plantsLoading}
                    isMulti={true}
                    customDisplay={(option) =>
                      `${option.value} - ${option.label}`
                    }
                    initialValue={values.materials[
                      props.promoItemIndex
                    ].plants?.map((p) => ({ value: p.code, label: p.name }))}
                    options={plants.map((v) => ({
                      value: v.code,
                      label: v.name,
                    }))}
                    onChange={(value) =>
                      setFieldValue(
                        `materials[${props.promoItemIndex}].plants`,
                        value.map((v) => ({ code: v.value, name: v.label }))
                      )
                    }
                  /> */}
          <TextFieldWrapper
            fullWidth
            size="small"
            name="shortDescription"
            label={`Short Description ${
              !(
                CampaignMaterialSchema.describe().fields[
                  "shortDescription"
                ] as any
              )?.optional
                ? "*"
                : ""
            }`}
          />

          <TextFieldWrapper
            fullWidth
            size="small"
            name="unitPrice"
            label={`Unit Price ${
              !(CampaignMaterialSchema.describe().fields["unitPrice"] as any)
                ?.optional
                ? "*"
                : ""
            }`}
          />

          <CustomCurrencySelect
            required={
              !(CampaignMaterialSchema.describe().fields["currencyCode"] as any)
                ?.optional
            }
            value={values.currencyCode}
            onChange={(newValue) => setFieldValue(`currencyCode`, newValue)}
          />

          <TextFieldWrapper
            name={`pricePer`}
            label={`Price Per 
                        ${
                          !(
                            CampaignMaterialSchema.describe().fields[
                              "pricePer"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
            size="small"
            fullWidth
          />

          <TextFieldWrapper
            name={`itemDescription`}
            label={`Scope of Application 
                        ${
                          !(
                            CampaignMaterialSchema.describe().fields[
                              "itemDescription"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
            size="small"
            fullWidth
          />

          <TextFieldWrapper
            name={`supplierPartId`}
            label={`Supplier Part ID 
                        ${
                          !(
                            CampaignMaterialSchema.describe().fields[
                              "supplierPartId"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
            size="small"
            fullWidth
            slotProps={{
              input: {
                endAdornment: !values.supplierPartId ? null : (
                  <BarcodeButton value={values.supplierPartId || ""} />
                ),
              },
            }}
          />

          <TextFieldWrapper
            name={`manufacturerName`}
            label={`Manufacturer Name 
                        ${
                          !(
                            CampaignMaterialSchema.describe().fields[
                              "manufacturerName"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
            size="small"
            fullWidth
          />

          <TextFieldWrapper
            name={`manufacturerPartNumber`}
            label={`Manufacturer Part Number 
                        ${
                          !(
                            CampaignMaterialSchema.describe().fields[
                              "manufacturerPartNumber"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
            size="small"
            fullWidth
            slotProps={{
              input: {
                endAdornment: !values.manufacturerPartNumber ? null : (
                  <BarcodeButton value={values.manufacturerPartNumber || ""} />
                ),
              },
            }}
          />

          <TextFieldWrapper
            name={`supplierLeadTime`}
            label={`Supplier Lead Time 
                        ${
                          !(
                            CampaignMaterialSchema.describe().fields[
                              "supplierLeadTime"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
            size="small"
            fullWidth
          />

          <MuiChipsInput
            error={!!getFieldMeta(`keyWords`).error}
            helperText={getFieldMeta(`keyWords`).error}
            value={values.keyWords}
            onChange={(newValue) => setFieldValue(`keyWords`, newValue)}
            size="small"
            fullWidth
            label={`Key Words 
                    ${
                      !(
                        CampaignMaterialSchema.describe().fields[
                          "keyWords"
                        ] as any
                      )?.optional
                        ? "*"
                        : ""
                    }`}
          />

          <CustomLanguageSelect
            required={
              !(CampaignMaterialSchema.describe().fields["languageId"] as any)
                ?.optional
            }
            error={getFieldMeta(`languageId`).error}
            value={values.languageId}
            onChange={(newValue) => setFieldValue(`languageId`, newValue)}
          />
        </Stack>
      )}
    </Formik>
  );
};

export default CampaignMaterialForm;
