import {
  Star,
  Favorite,
  AddShoppingCart,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  FavoriteOutlined,
  FavoriteBorderOutlined,
  FavoriteBorder,
  Check,
  Close,
  CheckOutlined,
  ErrorOutline,
  ErrorOutlined,
} from "@mui/icons-material";
import {
  Card,
  CardHeader,
  Avatar,
  CardActions,
  ButtonGroup,
  Button,
  Box,
  MobileStepper,
  useTheme,
  Chip,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import { PUIContentCarousel } from "modules/UI/components/ContentCarousel.component";
import PUIRatioBox from "modules/UI/components/RatioBox.component";
import React from "react";
import StaticResources from "service/dummyData/resources";
import { PslMaterial } from "../domains/pslMaterial.domain";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PromoItemsService from "../services/promoItems.service";
import { toTitleCase } from "utils/utils";
import EasyRequestService from "modules/EasyRequest/service/easyRequest.service";
import { toast } from "react-toastify";
import { environment } from "env";
import { getFormStatus } from "../domains/psl.domain";
import { useActiveUser } from "modules/User/activeUser";

export const PromoItemCard = ({
  promoItem,
  onRefetch,
  onPreview,
}: {
  promoItem;
  onRefetch?: (promoItem: any) => void;
  onPreview?: () => void;
}) => {
  const queryClient = useQueryClient();
  const activeUser = useActiveUser();
  const { mutateAsync: addToCart, status: addToCartStatus } = useMutation({
    mutationKey: ["addToCart"],
    mutationFn: async () => {
      return EasyRequestService.addPslMaterialById({
        materialId: promoItem.materialId,
      });
    },
    onSuccess: (res) => {
      queryClient.refetchQueries({ queryKey: ["easyRequestCounter"] });
      toast.success(
        `Material added to PR ${res[0].prId}. Click here to see Cart.`,
        {
          onClick: () => {
            window.open(environment.EASY_REQUEST_URL, "_blank");
          },
        }
      );
    },
  });
  const { mutateAsync: toggleFavorite, status } = useMutation({
    mutationKey: ["toggleFavorite"],
    mutationFn: async () => {
      await PromoItemsService.toggleFavoriteMaterial({
        pslId: promoItem.pslId,
      });
    },
    onSuccess: () => {
      if (onRefetch) onRefetch(promoItem);
    },
  });
  const promoCategory = StaticResources.promoCategories.find(
    (c) => c.id?.toString() === promoItem.promoCategoryId?.toString()
  );
  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: 420,
        minHeight: "100%",
        display: "flex",
        flexFlow: "row wrap",
        flexGrow: 1,
        cursor: "pointer",
        "&:hover": {
          boxShadow: 10,
        },
      }}
      elevation={0}
      onClick={onPreview}
    >
      {/* <SwipeableTextMobileStepper row={promoItem} /> */}
      <Box sx={{ width: "100%" }}>
        {promoItem.mediaFiles.length > 0 ? (
          <PUIContentCarousel
            data={promoItem.mediaFiles.map((file) => ({
              type: "image",
              thumbnail: file.url,
              src: file.url,
            }))}
            onPreview={onPreview}
          />
        ) : (
          <PUIRatioBox xRatio={16} yRatio={9}>
            <Avatar
              variant="square"
              sx={{
                width: "100%",
                height: "100%",
                // background: "linear-gradient(112.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(157.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(135deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(90deg, rgb(195, 195, 195),rgb(228, 228, 228)); background-blend-mode:overlay,overlay,overlay,multiply",
                backgroundColor: promoCategory?.color || "#ccc",
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,1), rgba(255,255,255,.1))",
                backgroundBlendMode: "overlay",
                "& svg": {
                  opacity: 0.2,
                  width: "50%",
                  height: "50%",
                },
              }}
            >
              {promoCategory ? <promoCategory.icon /> : <></>}
            </Avatar>
          </PUIRatioBox>
        )}
      </Box>

      <CardHeader
        // avatar={<Avatar sx={{ bgcolor: orange[500] }}>M</Avatar>}
        sx={{
          maxWidth: "100%",
          marginBottom: "auto",
          ".MuiCardHeader-title": {
            fontSize: "1rem",
            fontWeight: "bold",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
          "& *": {
            maxWidth: "100%",
          },
        }}
        title={
          <Tooltip title={`${promoItem.shortDescription}`}>
            {toTitleCase(`${promoItem.shortDescription}`)}
          </Tooltip>
        }
        subheader={
          <>
            <Tooltip
              title={`${promoItem.supplierName} | ${promoItem.statusName}`}
            >
              <Box
                sx={{
                  overflow: "hidden",
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {promoItem.supplierName}
              </Box>
            </Tooltip>{" "}
            {}
          </>
        }
      />
      {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"ß
      /> */}
      <CardActions sx={{ justifyContent: "space-between", width: "100%" }}>
        {/* <span>
          <Star sx={{ color: orange[500] }} /> 4.5
        </span> */}
        {/* <ButtonGroup> */}

        <Button
          aria-label="add to favorites"
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            toggleFavorite();
          }}
          disabled={status === "pending"}
        >
          {promoItem.isFavorite ? <Favorite /> : <FavoriteBorder />}
        </Button>
        {PslMaterial.build(promoItem).canBeRequested() &&
          !activeUser.isViewOnly() && (
            <Button
              variant="contained"
              size="medium"
              color="primary"
              startIcon={
                addToCartStatus === "pending" ? (
                  <CircularProgress size={14} />
                ) : addToCartStatus === "success" ? (
                  <CheckOutlined />
                ) : addToCartStatus === "error" ? (
                  <ErrorOutline />
                ) : (
                  <AddShoppingCart />
                )
              }
              disabled={addToCartStatus === "pending"}
              onClick={(e) => {
                e.stopPropagation();
                addToCart();
              }}
            >
              Add To Cart
            </Button>
          )}
        {/* </ButtonGroup> */}
      </CardActions>
    </Card>
  );
};

export default PromoItemCard;
