import {
  Alert,
  AlertTitle,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { CustomFastAsyncSelect } from "modules/Forms/components/form.legacy.component";
import { SUPPLIER_NOT_DEFINED_CODE } from "modules/PromoItems/containers/createStepOne.container";
import PromoItemsService from "modules/PromoItems/services/promoItems.service";
import { UserFullScopeComponent } from "modules/User/components/userScopeSelector/userFullScopeComponent";
import { values } from "ramda";
import React from "react";
import CampaignsService from "../service/campaigns.service";
import { CreateCampaignSchema } from "../service/campaigns.schemas";
import { CreateCampaignDTO } from "../service/campaigns.types";
import { useMutation } from "@tanstack/react-query";
import { InferType } from "yup";
import TextFieldWrapper from "modules/Forms/components/textFIeldWrapper.component";
import { DatePickerWrapper } from "modules/Forms/components/datePickerWrapper.component";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SelectSupplierContact from "modules/Forms/components/selectSupplierContact.component";
import { MediaManager } from "modules/PromoItems/components/MediaManager.component";

type CreateCampaignContainerProps = {
  onClose?: () => void;
};

export const CreateCampaignContainer = (
  props: CreateCampaignContainerProps
) => {
  const { onClose } = props;
  const navigate = useNavigate();
  const initialValues: InferType<typeof CreateCampaignSchema> = {
    mediaFiles: [],
  };
  const { mutateAsync: createCampaign, isPending: creatingCampaign } =
    useMutation({
      mutationKey: ["createCampaign", values],
      mutationFn: CampaignsService.createCampaign,
      onSuccess: (data) => {
        toast.success(`Campaign ${data.id} created successfully`, {
          onClick: () => {
            navigate(`/campaigns/${data.id}`);
          },
        });
      },
    });
  const { mutateAsync: uploadCampaignMedia } = useMutation<any, any, any>({
    mutationKey: ["uploadCampaignMedia"],
    mutationFn: async (data) => {
      const response = await CampaignsService.uploadCampaignMedia(data);
      return response;
    },
  });

  // const { mutateAsync: uploadCampaignMediaFromLink } = useMutation<
  //   any,
  //   any,
  //   any
  // >({
  //   mutationKey: ["uploadCampaignMediaFromLink"],
  //   mutationFn: async ({ link }) => {
  //     const response = await CampaignsService.uploadCampaignMediaFromLink({
  //       link,
  //     });
  //     return response;
  //   },
  // });

  const { mutateAsync: deleteCampaignMedia } = useMutation<any, any, any>({
    mutationKey: ["deleteCampaignMedia"],
    mutationFn: async (fileId) => {
      await CampaignsService.deleteCampaignMedia({ fileId });
      return fileId;
    },
  });
  return (
    <Formik
      validationSchema={CreateCampaignSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        createCampaign(values);
        if (onClose) onClose();
      }}
    >
      {({
        handleSubmit,
        isValid,
        errors,
        getFieldMeta,
        setValues,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} style={{ height: "100%" }}>
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            sx={{ height: "100%" }}
          >
            <Grid item sx={{ flex: "1 0 420px" }}>
              <Paper sx={{ p: 2, minHeight: "100%" }}>
                <Typography sx={{ mb: 1 }} variant="h5">
                  Campaign
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Name:</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextFieldWrapper name="name" size="small" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Description:</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextFieldWrapper
                      multiline
                      rows={4}
                      size="small"
                      fullWidth
                      name="description"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Start Date:</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <DatePickerWrapper
                      name="startDate"
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>End Date:</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <DatePickerWrapper
                      name="endDate"
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Scope:</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <UserFullScopeComponent
                      hasError={false}
                      initialMarkets={[]}
                      initialCompanies={[]}
                      initialZones={[]}
                      onChange={(newValues) => {
                        console.log({ newValues });
                      }}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MediaManager
                      images={values.mediaFiles || []}
                      // onUploadFromUrl={(link) => {
                      //   uploadCampaignMediaFromLink({
                      //     link,
                      //     campaignId: values.id,
                      //   })
                      //     .then((response) => {
                      //       setFieldValue(
                      //         "mediaFiles",
                      //         values.mediaFiles.concat(response)
                      //       );
                      //     })
                      //     .catch((err) => {
                      //       console.error(err);
                      //     });
                      // }}
                      onUpload={(files) => {
                        uploadCampaignMedia({
                          file: files[0],
                          campaignId: values.id,
                        })
                          .then((response) => {
                            setFieldValue(
                              "mediaFiles",
                              values.mediaFiles.concat(response)
                            );
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                      onDelete={(mediaId) => {
                        deleteCampaignMedia(mediaId).then((deletedFileId) => {
                          setFieldValue(
                            "mediaFiles",
                            values.mediaFiles.filter(
                              (f) => f.fileId !== deletedFileId
                            )
                          );
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item sx={{ flex: "1 0 420px" }}>
              <Paper sx={{ p: 2, minHeight: "100%" }}>
                <Typography sx={{ mb: 1 }} variant="h5">
                  Supplier
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Company:</FormLabel>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{ position: "relative", zIndex: 2 }}
                  >
                    <CustomFastAsyncSelect
                      onLoad={(searchTerm) => {
                        return PromoItemsService.getPslAmdrSuppliersHierarchy({
                          searchTerm,
                        }).then((results) => {
                          results.push({
                            id: SUPPLIER_NOT_DEFINED_CODE,
                            name: "Supplier Not Defined",
                          });
                          return results;
                        });
                      }}
                      onLoadLimit={3}
                      hasError={!!getFieldMeta("supplierCode").error}
                      customDisplay={(option) =>
                        `${option.id} - ${option.name}`
                      }
                      initialValue={null}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          supplierCode: value?.id,
                          supplierName: value?.name,
                        });
                      }}
                    />
                    {!!getFieldMeta("supplierCode").error && (
                      <FormHelperText error>
                        {getFieldMeta("supplierCode").error}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Contact:</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <SelectSupplierContact
                      supplierId={values.supplierCode}
                      value={values.supplierContact?.email}
                      onChange={(e) => {
                        setFieldValue("supplierContact.email", e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormLabel>Scope:</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    LATAM, NORTH AMERICA, AOA, EMENA
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="error" variant="outlined">
                      <AlertTitle>
                        This suppplier is not extended to your region.
                      </AlertTitle>
                      To Proceed, please maintain VFT.
                    </Alert>
                  </Grid>
                </Grid>
              </Paper>
            </Grid> 
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
              sx={{ mt: "auto" }}
            >
              <Button
                type="reset"
                color="primary"
                variant="outlined"
                size="large"
                sx={{ ml: "auto" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                sx={{ ml: 2 }}
                disabled={!isValid || creatingCampaign}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default CreateCampaignContainer;
