import { PromoItemRowSchema } from "modules/PromoItems/services/promoItems.schemas";
import { IdNamePairSchema, ShortUserSchema } from "service/generic.schemas";
import * as yup from "yup";

export const MediaFileSchema = yup.object().shape({
  fileId: yup.number().required(),
  url: yup.string().required(),
  mimeType: yup.string().required(),
  originalFileName: yup.string().required(),
  persistedFileNameInAzureStorage: yup.string().required(),
});

export const CreateCampaignSchema = yup.object().shape({
  id: yup.number().nullable().notRequired(),
  name: yup.string().required("This field is required"),
  description: yup.string().required("This field is required"),
  startDate: yup
    .string()
    .datetime("Enter valid date")
    .required("This field is required"),
  endDate: yup
    .string()
    .datetime("Enter valid date")
    .required("This field is required"),
  supplierCode: yup.string().required("This field is required"),
  supplierName: yup.string().required("This field is required"),
  supplierContact: ShortUserSchema,
  zones: yup.array().of(IdNamePairSchema),
  markets: yup.array().of(IdNamePairSchema),
  companyCodes: yup.array().of(IdNamePairSchema),
  mediaFiles: yup.array().of(MediaFileSchema),
});

export const CampaignMaterialSchema = PromoItemRowSchema.shape({
  // we're exending existing schema with new fields.
  minimumOrderQuantity: yup.number().required("This field is required"),
  minimumPackagingQuantity: yup.number().required("This field is required"),
  priceRanges: yup.array().of(
    yup.object().shape({
      minimumQuantity: yup.number().required("This field is required"),
      pricePerUnit: yup.number().required("This field is required"),
    })
  ),
});
