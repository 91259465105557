import {
  Add,
  AddShoppingCart,
  BrowseGallery,
  Close,
  Collections,
  ContentCopy,
  Edit,
  FileCopy,
  NavigateNext,
  NotificationAddOutlined,
  PlayArrow,
  Remove,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  CardContent,
  CardMedia,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Card,
  Grid,
  CardActions,
  Button,
  ButtonGroup,
  CardHeader,
  TextField,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Container,
  FormLabel,
  Alert,
  AlertTitle,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Slide } from "modules/Layout/views/landing.view";
import PromoItemRow from "modules/PromoItems/components/PromoItemRow.component";
import ActivityLog from "modules/UI/components/ActivityLog.component";
import { Attachments } from "modules/UI/components/Attachments.component";
import PUIAvatar from "modules/UI/components/Avatar.component";
import Drawer from "modules/UI/components/Drawer.component";
import { UserFullScopeComponent } from "modules/User/components/userScopeSelector/userFullScopeComponent";
import React, { useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import CreateCampaignContainer from "./create.container";
import { MaterialReactTable } from "material-react-table";
import { Doughnut, Line, Pie } from "react-chartjs-2";
import "chart.js/auto";
import { Formik, FormikHelpers, FormikValues } from "formik";
import CampaignMaterialForm from "../components/CampaignMaterialForm.component";
import {
  AuthenticatedImage,
  AuthenticatedImageBackgroundDiv,
} from "modules/UI/components/AuthenticatedImage.component";

const data = [
  {
    Country: "Bulgaria",
    "Nestle SH": "ivan.ivanov@nestle.com",
    Code: "notebook_1",
    "Quantity (units)": 5000,
    "MOQ met": true,
    "Consolidated FOB sell price": "1.5",
    "Sell Price": "1.8",
    Incoterm: "DDP",
    "Delivery Address": "Bulgaria, Sofia",
    Date: "2021-11-23",
  },
  {
    Country: "Bulgaria",
    "Nestle SH": "ivan.ivanov@nestle.com",
    Code: "notebook_2",
    "Quantity (units)": 4000,
    "MOQ met": true,
    "Consolidated FOB sell price": "1.5",
    "Sell Price": "1.8",
    Incoterm: "DDP",
    "Delivery Address": "Bulgaria, Sofia",
    Date: "2021-11-23",
  },
  {
    Country: "Spain",
    "Nestle SH": "ivan.ivanov@nestle.com",
    Code: "notebook_1",
    "Quantity (units)": 3000,
    "MOQ met": false,
    "Consolidated FOB sell price": "1.5",
    "Sell Price": "1.5",
    Incoterm: "FOB",
    "Delivery Address": "Spain, Barcelona",
    Date: "2021-11-23",
  },
  {
    Country: "France",
    "Nestle SH": "ivan.ivanov@nestle.com",
    Code: "notebook_2",
    "Quantity (units)": 3000,
    "MOQ met": false,
    "Consolidated FOB sell price": "1.5",
    "Sell Price": "1.5",
    Incoterm: "FOB",
    "Delivery Address": "France, Paris",
    Date: "2021-11-24",
  },
];

export const CampaignDetailsContainer = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { campaignId } = useParams();
  const [tab, setTab] = React.useState(0);
  const theme = useTheme();
  const ref = useRef();
  return (
    <>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        title="Product details"
      >
        <CampaignMaterialForm />
        {/* <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label="Category" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Material Number" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Short description" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Unit Price" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Currency" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Price Per" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Unit of Measure" />
          </Grid>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Minimum Quantity</TableCell>
                  <TableCell>Unit Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>4,0</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>50</TableCell>
                  <TableCell>3,0</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>100</TableCell>
                  <TableCell>2,5</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>150</TableCell>
                  <TableCell>2,3</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Scope of application" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Supplier Part Id" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Manufacturer Name" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Manufacturer Part Number" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Supplier Lead Time" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Tags" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Language" />
          </Grid>
        </Grid> */}
      </Drawer>
      <Box
        sx={{ m: -3, mb: 3, p: 3, background: theme.palette.background.paper }}
      >
        <Breadcrumbs
          sx={{ mb: 3 }}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {[
            <NavLink key="1" color="inherit" to="/campaigns" onClick={() => {}}>
              Campaigns
            </NavLink>,
            <Typography key="3" color="text.primary">
              {campaignId ? `Campaign #${campaignId}` : "New Campaign"}
            </Typography>,
          ]}
        </Breadcrumbs>
        <Grid
          container
          spacing={4}
          justifyContent={"space-between"}
          alignContent={"center"}
        >
          <Grid item>
            <Typography variant="h4">Crete new Campaign</Typography>
            <Typography variant="h6">Subtitle</Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Subcribe to this campaign to receive updates">
              <Button
                size="large"
                variant="contained"
                startIcon={<NotificationAddOutlined />}
              >
                Subscribe
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Stepper activeStep={1} alternativeLabel sx={{ my: 6 }}>
          <Step>
            <StepLabel>OW Created</StepLabel>
          </Step>
          <Step>
            <StepLabel optional="25-07-2024">Order Window Opens</StepLabel>
          </Step>
          <Step>
            <StepLabel optional="Pending/Reached">
              Minimum Order Quantity
            </StepLabel>
          </Step>
          <Step>
            <StepLabel optional="08-08-2024">Order Window Closed</StepLabel>
          </Step>
          <Step>
            <StepLabel optional="final prices quoted">
              Quantities consolidated
            </StepLabel>
          </Step>
          <Step>
            <StepLabel optional="final prices quoted">PO's Issued</StepLabel>
          </Step>
          <Step>
            <StepLabel optional="09-12-2024">Delivery</StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Container maxWidth="xl">
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12}>
            <CreateCampaignContainer />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={tab}
                onChange={(_, newValue) => setTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Materials" />
                <Tab label="Attachments" />
                <Tab label="Subscribers" />
                <Tab label="Activity Log" />
                <Tab label="Quantity Consolidation" />
              </Tabs>

              {tab === 0 && (
                <Box padding={2}>
                  <Grid container spacing={2}>
                    {new Array(3).fill(
                      <Grid item xs={12}>
                        <Card sx={{ display: "flex", width: "100%" }}>
                          <CardMedia
                            component="img"
                            sx={{ width: 151 }}
                            image="https://i.ytimg.com/vi/5n4J9duypeQ/maxresdefault.jpg"
                            alt="product image"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              flex: "1 1 auto",
                              alignItems: "flex-start",
                            }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <Typography component="div" variant="h5">
                                Insulated aluminium bottle
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ color: "text.secondary" }}
                              >
                                Aluminium food contact
                              </Typography>
                            </CardContent>
                            <CardActions sx={{ justifyContent: "flex-end" }}>
                              <ButtonGroup
                                variant="outlined"
                                size="medium"
                                color="primary"
                              >
                                <Button
                                  startIcon={<Collections />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  Media
                                </Button>
                                <Button
                                  startIcon={<Close />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  Remove
                                </Button>
                                <Button
                                  startIcon={<FileCopy />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  Duplicate
                                </Button>
                                <Button
                                  startIcon={<Edit />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDrawerOpen(true);
                                  }}
                                >
                                  Edit
                                </Button>
                              </ButtonGroup>
                            </CardActions>
                          </Box>
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}

              {tab === 1 && (
                <Box padding={2}>
                  <Attachments
                    enableDelete
                    enableUpload
                    onClick={() => {}}
                    onAttach={() => {}}
                    onDelete={() => {}}
                    attachments={[
                      {
                        creationDate: "2021-11-23 12:32",
                        fileName: "design.jpg",
                        id: "12312312",
                        uploadedByUserEmail: "sampleuser@nestle.com",
                      },
                      {
                        creationDate: "2021-11-23 12:32",
                        fileName: "design.jpg",
                        id: "12312312",
                        uploadedByUserEmail: "sampleuser@nestle.com",
                      },
                      {
                        creationDate: "2021-11-23 12:32",
                        fileName: "design.jpg",
                        id: "12312312",
                        uploadedByUserEmail: "sampleuser@nestle.com",
                      },
                      {
                        creationDate: "2021-11-23 12:32",
                        fileName: "design.jpg",
                        id: "12312312",
                        uploadedByUserEmail: "sampleuser@nestle.com",
                      },
                    ]}
                  />
                </Box>
              )}
              {tab === 2 && <>Subscribers</>}
              {tab === 3 && (
                <ActivityLog
                  entries={[
                    {
                      performedAt: "2023-12-12 12:32",
                      performedByEmail: "johnsmith@nestle.com",
                      performedByUserName: "John Smith",
                      activityText: "Deleted the campaign",
                      additionalInformation: "more info here",
                    },
                    {
                      performedAt: "2022-12-12 12:32",
                      performedByEmail: "johnsmith@nestle.com",
                      performedByUserName: "John Smith",
                      activityText: "Updated the campaign",
                      additionalInformation: "more info here",
                    },
                    {
                      performedAt: "2021-12-12 12:32",
                      performedByEmail: "johnsmith@nestle.com",
                      performedByUserName: "John Smith",
                      activityText: "Created the campaign",
                      additionalInformation: "more info here",
                    },
                  ]}
                />
              )}
              {tab === 4 && (
                <Box padding={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Card>
                        <CardContent>
                          <Doughnut
                            ref={ref}
                            options={{
                              plugins: {
                                title: {
                                  display: true,
                                  text: "Quantities by country",
                                },
                                legend: {
                                  display: true,
                                  position: "bottom",
                                },
                              },
                            }}
                            data={{
                              labels: Object.keys(
                                data.reduce((acc, curr) => {
                                  acc[curr.Country] =
                                    (acc[curr.Country] || 0) +
                                    curr["Quantity (units)"];
                                  return acc;
                                }, {})
                              ),
                              datasets: [
                                {
                                  label: "Quantity (units)",
                                  data: Object.values(
                                    data.reduce((acc, curr) => {
                                      acc[curr.Country] =
                                        (acc[curr.Country] || 0) +
                                        curr["Quantity (units)"];
                                      return acc;
                                    }, {})
                                  ),
                                  backgroundColor: [
                                    theme.palette.primary.main,
                                    theme.palette.secondary.main,
                                    theme.palette.error.main,
                                  ],
                                },
                              ],
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card>
                        <CardContent>
                          <Doughnut
                            ref={ref}
                            options={{
                              plugins: {
                                title: {
                                  display: true,
                                  text: "Quantities by material",
                                },
                                legend: {
                                  display: true,
                                  position: "bottom",
                                },
                              },
                            }}
                            data={{
                              labels: Object.keys(
                                data.reduce((acc, curr) => {
                                  acc[curr.Code] =
                                    (acc[curr.Code] || 0) +
                                    curr["Quantity (units)"];
                                  return acc;
                                }, {})
                              ),
                              datasets: [
                                {
                                  label: "Quantity (units)",
                                  data: Object.values(
                                    data.reduce((acc, curr) => {
                                      acc[curr.Code] =
                                        (acc[curr.Code] || 0) +
                                        curr["Quantity (units)"];
                                      return acc;
                                    }, {})
                                  ),
                                  backgroundColor: [
                                    theme.palette.primary.main,
                                    theme.palette.secondary.main,
                                    theme.palette.error.main,
                                  ],
                                },
                              ],
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card>
                        <CardContent>
                          <Line
                            ref={ref}
                            options={{
                              plugins: {
                                title: {
                                  display: true,
                                  text: "Quantities by date",
                                },
                                legend: {
                                  display: true,
                                  position: "bottom",
                                },
                              },
                            }}
                            data={{
                              labels: Object.keys(
                                data.reduce((acc, curr) => {
                                  acc[curr.Date] =
                                    (acc[curr.Date] || 0) +
                                    curr["Quantity (units)"];
                                  return acc;
                                }, {})
                              ),
                              datasets: [
                                {
                                  label: "Quantity (units)",
                                  data: Object.values(
                                    data.reduce((acc, curr) => {
                                      acc[curr.Date] =
                                        (acc[curr.Date] || 0) +
                                        curr["Quantity (units)"];
                                      return acc;
                                    }, {})
                                  ),
                                  backgroundColor: [
                                    theme.palette.primary.main,
                                    theme.palette.secondary.main,
                                    theme.palette.error.main,
                                  ],
                                },
                              ],
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <MaterialReactTable
                        muiTablePaperProps={({ table }) => ({
                          style: { background: "white !important" },
                          position: "relative",
                          zIndex: table.getState().isFullScreen
                            ? 1300
                            : undefined,
                        })}
                        data={data}
                        columns={[
                          {
                            header: "Country",
                            accessorKey: "Country",
                          },
                          {
                            header: "Nestle SH",
                            accessorKey: "Nestle SH",
                          },
                          {
                            header: "Code",
                            accessorKey: "Code",
                          },
                          {
                            header: "Quantity (units)",
                            accessorKey: "Quantity (units)",
                          },
                          {
                            header: "MOQ met",
                            accessorKey: "MOQ met",
                          },
                          {
                            header: "FOB sell price",
                            accessorKey: "Consolidated FOB sell price",
                          },
                          {
                            header: "Total sell Price",
                            accessorKey: "Sell Price",
                          },
                          {
                            header: "Incoterm",
                            accessorKey: "Incoterm",
                          },
                          {
                            header: "Delivery Address",
                            accessorKey: "Delivery Address",
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CampaignDetailsContainer;
