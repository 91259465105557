import React, { useEffect, useState } from "react";
import Drawer from "modules/UI/components/Drawer.component";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { UserCategoryComponent } from "./userScopeSelector/userCategorySelector";
import { CustomFastInput } from "modules/Forms/components/form.legacy.component";
import { UserScopeComponent } from "./userScopeSelector";
import { ActiveUserContext, useActiveUser } from "../activeUser";
import PUIUser from "modules/UI/components/User.component";
import PUIAvatar from "modules/UI/components/Avatar.component";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { UserService } from "../service/user.service";
import UserRoleSwitcher from "./UserRoleSwitcher.component";
import { environment } from "env";
import { toast } from "react-toastify";

export const UserSettingsDrawer = (props: {
  open?: boolean;
  onClose?: () => void;
}) => {
  // const currentUserContext = React.useContext(ActiveUserContext);
  const resetUserScopeMutation = useMutation({
    mutationKey: ["resetUserScopeMutation"],
    mutationFn: () => UserService.resetUserAuthorizedScopeOnPreProd({}),
    onSuccess: () => {
      toast.success("User scope reset successfully");
    },
  });
  const currentUser = useActiveUser();
  const findRole = (options, role) => {
    return options.find((el) => el.value === role);
  };
  const [prpoSettings, setPrpoSettings] = useState({
    role: { options: [], selected: null },
    markets: currentUser.filters?.userScope.markets,
    companyCodes: currentUser.filters?.userScope.companies,
    spendCatL1s: currentUser.filters?.userScope.spendCatL1s,
    spendCatL2s: currentUser.filters?.userScope.spendCatL2s,
    spendCatL3s: currentUser.filters?.userScope.spendCatL3s,
    pslExpiryDays: currentUser.pslExpiryDays,
  });

  // return <></>;
  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      title="User Settings"
      headerContent={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
          }}
        >
          <PUIAvatar size={84} src={currentUser.photoUrl} />
        </Box>
      }
      footerContent={
        environment.ENV !== "PromoCatalog-PP" ? null : (
          <ButtonGroup>
            <Button
              disabled={resetUserScopeMutation.isPending}
              onClick={() => resetUserScopeMutation.mutate()}
            >
              Reset User Scope
            </Button>
          </ButtonGroup>
        )
      }
    >
      {
        <Box>
          <Table>
            <TableBody>
              {[
                {
                  name: "User Type",
                  value: currentUser.userType,
                },
                {
                  name: "Name",
                  value: currentUser.name,
                },
                {
                  name: "E-mail",
                  value: currentUser.email,
                },
                {
                  name: "Preferred Company",
                  value: (
                    <>
                      {currentUser.userPreferredCompanyCode} -{" "}
                      {currentUser.userPreferredCompanyName}
                    </>
                  ),
                },
                {
                  name: "Role",
                  value: (
                    <>
                      <UserRoleSwitcher />
                    </>
                  ),
                },
                ...(currentUser.contactRoles
                  ? [
                      {
                        name: "Contact Roles",
                        value: currentUser.contactRoles
                          .map((role) => role.name)
                          .join(", "),
                      },
                    ]
                  : []),
                {
                  name: "Location",
                  value: (
                    <>
                      <UserScopeComponent
                        disabled={true}
                        company={`${currentUser.companyCode} - ${currentUser.company}`}
                        initialMarkets={currentUser.filters?.userScope.markets}
                        initialCompanies={
                          currentUser.filters?.userScope.companies
                        }
                        hasError={false}
                        onChange={(values) => {
                          // setPrpoSettings({
                          //   ...prpoSettings,
                          //   markets: values.markets,
                          //   companyCodes: values.companyCodes
                          // });
                        }}
                      />
                    </>
                  ),
                },
                {
                  name: "Categories",
                  value: (
                    <>
                      <UserCategoryComponent
                        disabled={true}
                        initialSpendCatL1s={
                          currentUser.filters.userScope.spendCatL1s
                        }
                        initialSpendCatL2s={
                          currentUser.filters.userScope.spendCatL2s
                        }
                        initialSpendCatL3s={
                          currentUser.filters.userScope.spendCatL3s
                        }
                        onChange={(values) => {
                          setPrpoSettings({
                            ...prpoSettings,
                            spendCatL1s: values.spendCatL1s,
                            spendCatL2s: values.spendCatL2s,
                            spendCatL3s: values.spendCatL3s,
                          });
                        }}
                        hasError={false}
                      />
                    </>
                  ),
                },
                {
                  name: "PSL Expiration Days",
                  value: <>{prpoSettings.pslExpiryDays}</>,
                },
              ].map((field) => (
                <TableRow>
                  <TableCell>
                    <strong>{field.name}</strong>
                  </TableCell>
                  <TableCell>{field.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      }
    </Drawer>
  );
};

export default UserSettingsDrawer;
